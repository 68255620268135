export const environment = {
    production: true,
    release: '${RELEASE}',
    serverAddress: 'https://www.test.app.shop-iq.eu',
    controllerAddress: 'wss://test.ssc.shop-iq.eu/ws',
    magicInfoServerAddress: 'test.ssc.shop-iq.eu',
    currentYear: 2022,

    nodeServerExternalHost: undefined,
    nodeServerPort: 26517,
    nodeServerWsInternalPath: '/aixaixah0Yaengaeyoh8oop6Echo7ieSeifeeph2Keit0sha',

    androidUpdateUrl: 'https://shopiq-android-update.s3.eu-central-1.amazonaws.com/test/',

    hawkbitServerUrl: undefined,
    hawkbitClientCert: undefined,
};
