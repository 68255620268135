import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {TimeServiceBase} from './time.service.base';

@Injectable()
export class TimeService extends TimeServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    static now(): moment.Moment {
        return moment();
    }

    static nowMillis(_1: boolean = true): number {
        return Date.now();
    }
}
