import {Injectable, Injector} from '@angular/core';
import * as _ from 'lodash';
import {concat, defer, Observable, of} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {FileSyncServiceBase} from './file-sync.service.base';
import {SyncConfigDownloadEvent, SyncData, SyncEvent, SyncServiceBase} from './sync.service.base';

export enum SettingKeys {
    // sorted alphabetically
    AMBRIGHT_IP_ADDRESS = 'ambright.ip_address',
    AMBRIGHT_LINE = 'ambright.line',
    AMBRIGHT_SEGMENT = 'ambright.segment',
    ANDROID_WIFI_NAME = 'android.wifi_name',
    ANDROID_WIFI_PASSWORD = 'android.wifi_password',
    AUTO_PARAMETRIZATION_ECO = 'system_settings.auto_parametrization.eco_solution',
    CONTROLLER_CLIENT_ENABLED = 'controller_client_enabled',
    CONTROLLER_TOKEN = 'controller_token',
    CUSTOMER_ID = 'customer_id',
    CUSTOMER_NAME = 'customer_name',
    IR_REMOTE_CONTROL_LOCK = 'system_settings.infrared_remote_control_lock',
    ORIENTATION = 'screen_orientation',
    PLAYLIST_SYNCHRONIZATION_SCREENS = 'playlist_synchronization.screen_ids',
    POWER_BUTTON_LOCK = 'system_settings.panel_key_lock',
    REBOOT_INTERVAL = 'reboot_interval',  // Should be a multiple of 24 * 60 * 60
    SCREEN_ID = 'screen_id',
    SCREEN_NAME = 'screen_name',
    SELECTIVE_FILE_SYNC = 'selective_file_sync',
    SHOP_NAME = 'shop_name',
    SHOP_ZIP_CODE = 'shop_zip_code',
    TIME_ZONE = 'system_settings.time_zone',
}

export type EventEntityLayoutType = 'default' | 'current' | number;

export interface EventEntity {
    id: number;
    name: string;
    timeout: number;
    contents: Array<{
        screen: number,
        layout: EventEntityLayoutType,
        contents: Array<{
            id: number,
            period: number,
        }>,
    }>;
    conditions: Array<any>;
    screen: number;
}

export interface HtmlContent {
    id: number;
    template: number;
    html: string;
    ambright: {
        color?: number;
        saturation?: number;
        brightness?: number;
    };
}

export interface HtmlTemplateAsset {
    id: number;
    template: number;
    name: string;
    size: number;
}

export interface LayoutEntity {
    id: number;
    name: string;
    parameters: any;
    template: string;
    template_parameters: Array<string>;
}

export type LayoutAsset = {
    id: number | string;
    url: string;
    used: boolean;
} & ({ size: number; headUrl?: never; } | { headUrl: string; size?: never; });

export interface DayWeatherData {
    date: string | Date;
    minimum_temperature: number;
    maximum_temperature: number;
    icon: string;
}

@Injectable()
export class SettingsService {
    constructor(private injector: Injector) {
    }

    get<T>(key: string, defaultValue?: T): T {
        return _.get(this.getSyncData(), `settings.${key}`, defaultValue);
    }

    get$<T>(key: string, defaultValue?: T): Observable<T> {
        return this._get$(`settings.${key}`, defaultValue);
    }

    getActions(): Array<any> {
        return _.get(this.getSyncData(), 'actions', []);
    }

    getEvents(): Array<EventEntity> {
        return _.get(this.getSyncData(), 'events', []);
    }

    getEvent(id: number): EventEntity | undefined {
        return this.getEvents().find(event => event.id === id);
    }

    getContents(): Array<any> {
        return _.get(this.getSyncData(), 'contents', []);
    }

    getContent(id: number): any {
        return this.getContents().find(content => content.id === id);
    }

    getContentFileURL(id: number): string | undefined {
        const fileSync = this.injector.get(FileSyncServiceBase);
        const content = this.getContent(id);
        const fileName = `${id}.${content.type === 'picture' ? 'png' : 'mp4'}`;

        return fileSync.getContentFileURL(fileName);
    }

    getLayouts(): Array<LayoutEntity> {
        return _.get(this.getSyncData(), 'layouts', []);
    }

    getLayout(id: number): LayoutEntity | undefined {
        return this.getLayouts().find(layout => layout.id === id);
    }

    getLayoutAssets(): Array<LayoutAsset> {
        return _.get(this.getSyncData(), 'layout_assets', []);
    }

    getHolidays(): Array<any> {
        return _.get(this.getSyncData(), 'holidays', []);
    }

    getHtmlContents$(): Observable<Array<HtmlContent>> {
        return this._get$<Array<HtmlContent>>('html_contents', []);
    }

    getHtmlContent$(id: number): Observable<HtmlContent | undefined> {
        return this.getHtmlContents$().pipe(
            map(contents => contents.find(content => content.id === id)),
            distinctUntilChanged(),
        );
    }

    getHtmlTemplateAssetsForTemplate(id: number): Array<HtmlTemplateAsset> {
        const allAssets = _.get(this.getSyncData(), 'html_template_assets', []) as Array<HtmlTemplateAsset>;
        return allAssets.filter(asset => asset.template === id);
    }

    getCameras$(): Observable<Array<any>> {
        return this._get$<Array<any>>('cameras', []);
    }

    getWeather$(): Observable<Array<DayWeatherData>> {
        return this._get$<Array<any>>('weather', []);
    }

    private _get$<T>(key: string, defaultValue?: T): Observable<T> {
        return this.getSyncService().events.pipe(
            filter((event: SyncEvent): event is SyncConfigDownloadEvent =>
                event instanceof SyncConfigDownloadEvent && event.modified,
            ),
            map(event => _.get(event.syncData, key, defaultValue)),
            // Fetch current value on subscribe
            startWithFactory(() => _.get(this.getSyncData(), key, defaultValue)),
            distinctUntilChanged(),
        );
    }

    private getSyncData(): SyncData | undefined {
        return this.getSyncService().syncData;
    }

    private getSyncService(): SyncServiceBase {
        // We don't directly use syncService because it creates circular dependencies in some cases
        // this way the settings service can always safely be used
        return this.injector.get(SyncServiceBase);
    }
}

export function startWithFactory<T>(valueFactory: () => T): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
        concat(
            defer(() => of(valueFactory())),
            source,
        );
}
