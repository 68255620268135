/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./image-player.component";
var styles_ImagePlayerComponent = ["[_nghost-%COMP%] {\n            display: block;\n            position: absolute;\n            top: 0;\n            left: 0;\n            width: 100%;\n            height: 100%;\n        }\n\n        [_nghost-%COMP%]   img[_ngcontent-%COMP%] {\n            position: absolute;\n            top: 0;\n            left: 0;\n            width: 100%;\n            height: 100%;\n        }\n\n        .hidden[_ngcontent-%COMP%] {\n            z-index: 10;\n            visibility: hidden;\n            opacity: 0;\n            transition: visibility 0s 3s, opacity 1s linear 1.5s;\n        }"];
var RenderType_ImagePlayerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ImagePlayerComponent, data: {} });
export { RenderType_ImagePlayerComponent as RenderType_ImagePlayerComponent };
function View_ImagePlayerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [], [[8, "hidden", 0], [8, "src", 4]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { hidden: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, (_co.entryIdx != 1)); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hidden; var currVal_1 = _co.entrySources[0]; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImagePlayerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [], [[8, "hidden", 0], [8, "src", 4]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { hidden: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, (_co.entryIdx != 2)); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hidden; var currVal_1 = _co.entrySources[1]; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ImagePlayerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "img", [], [[8, "hidden", 0], [8, "src", 4]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { hidden: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, (_co.entryIdx != 0)); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hidden; var currVal_1 = _co.entrySources[2]; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ImagePlayerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImagePlayerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImagePlayerComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImagePlayerComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.entries[0] != null); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.entries[1] != null); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.entries[2] != null); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ImagePlayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "siq-image-player", [], null, null, null, View_ImagePlayerComponent_0, RenderType_ImagePlayerComponent)), i0.ɵdid(1, 49152, null, 0, i2.ImagePlayerComponent, [i0.ChangeDetectorRef], null, null)], null, null); }
var ImagePlayerComponentNgFactory = i0.ɵccf("siq-image-player", i2.ImagePlayerComponent, View_ImagePlayerComponent_Host_0, {}, { completed: "completed" }, []);
export { ImagePlayerComponentNgFactory as ImagePlayerComponentNgFactory };
