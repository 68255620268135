import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { filter, map } from 'rxjs/operators';
import { AbstractPlatform } from '../platforms/platform';
import { FileSyncServiceBase } from './file-sync.service.base';
import { SyncServiceBase } from './sync.service.base';
var SyncService = /** @class */ (function (_super) {
    tslib_1.__extends(SyncService, _super);
    function SyncService(http, router, fileSync, platform) {
        var _this = _super.call(this, http, router, fileSync, platform) || this;
        _this.route = _this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function (event) { return _this.router.routerState.snapshot.root.firstChild; }));
        _this.route.subscribe(function (route) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var hasSecretInUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(route && route.paramMap)) return [3 /*break*/, 3];
                        hasSecretInUrl = route.paramMap.has('secret');
                        if (!hasSecretInUrl) return [3 /*break*/, 3];
                        this.secret = route.paramMap.get('secret');
                        return [4 /*yield*/, this.storeSecret()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.router.navigate(['sync'], {})];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
        return _this;
    }
    SyncService.prototype.loadSecret = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (localStorage) {
                    this.secret = localStorage.getItem('secret') || undefined;
                }
                return [2 /*return*/];
            });
        });
    };
    SyncService.prototype.storeSecret = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.secret) {
                    localStorage ?
                        localStorage.setItem('secret', this.secret)
                        : this.logger.warn('cant save secret (local storage is undefined)');
                    // Update secret in sentry
                    Sentry.configureScope(function (scope) {
                        scope.setTag('server_name', _this.secret);
                    });
                }
                else {
                    if (localStorage) {
                        localStorage.removeItem('secret');
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    SyncService.prototype.loadConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var configJson;
            return tslib_1.__generator(this, function (_a) {
                configJson = localStorage ? localStorage.getItem('sync') : undefined;
                return [2 /*return*/, configJson ? JSON.parse(configJson) : undefined];
            });
        });
    };
    SyncService.prototype.storeConfig = function (config) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                localStorage ? localStorage.setItem('sync', JSON.stringify(config)) : this.logger.warn('cant save sync data (local storage is undefined)');
                return [2 /*return*/];
            });
        });
    };
    SyncService.prototype.getDeviceInfoPairs = function () {
        return _super.prototype.getDeviceInfoPairs.call(this).slice();
    };
    return SyncService;
}(SyncServiceBase));
export { SyncService };
