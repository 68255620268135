import {Component} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FileSyncServiceBase} from '../../sync/file-sync.service.base';
import {SettingsService} from '../../sync/settings.service';
import {BaseLayoutComponent} from './base-layout.component';
import {createIframeSrcDoc} from './iframe-utils';

interface Parameters {
    overlay_html: string;
    placeholder_settings: string;
    template: number;
    // if undefined then fullscreen is used
    content_dimensions?: {
        posX: number,
        posY: number,
        width: number,
        height: number,
    };
}

@Component({
    selector: 'siq-html-layout',
    // language=Angular2HTML
    styles: [`
        iframe {
            border: none;
            -webkit-transform-origin: 0 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
        }
    `],
    template: `
        <div [ngStyle]="contentStyle$ | async">
            <siq-video-player #videoPlayer (completed)="completed.emit()"></siq-video-player>
            <siq-image-player #imagePlayer (completed)="completed.emit()"></siq-image-player>
        </div>
        <iframe [srcdoc]="htmlCode$ | async"></iframe>
    `,
})
export class HtmlOverlayLayoutComponent extends BaseLayoutComponent {

    readonly parameters$!: Observable<Parameters>;

    readonly htmlCode$ = createHtmlCode$(this.fileSync, this.settings, this.sanitizer, this.parameters$);
    readonly contentStyle$ = createContentStyle$(this.parameters$);

    constructor(settings: SettingsService,
                fileSync: FileSyncServiceBase,
                private sanitizer: DomSanitizer) {
        super(settings, fileSync);
    }
}

function createHtmlCode$(
    fileSync: FileSyncServiceBase,
    settings: SettingsService,
    sanitizer: DomSanitizer,
    parameters$: Observable<Parameters>): Observable<SafeHtml> {
    return parameters$.pipe(map(parameters => {
        const assets = settings.getHtmlTemplateAssetsForTemplate(parameters.template);
        const source = createIframeSrcDoc(parameters.overlay_html, assets, fileSync);
        return sanitizer.bypassSecurityTrustHtml(source);
    }));
}

function createContentStyle$(
    parameters$: Observable<Parameters>): Observable<SafeHtml> {
    const addPx = (pixel?: number) => pixel ? `${pixel}px` : undefined;

    return parameters$.pipe(map(parameters => ({
        zIndex: '0',
        position: 'absolute',
        left: addPx(_.get(parameters, 'content_dimensions.posX', 0)),
        top: addPx(_.get(parameters, 'content_dimensions.posY', 0)),
        width: addPx(_.get(parameters, 'content_dimensions.width')) || '100%',
        height: addPx(_.get(parameters, 'content_dimensions.height')) || '100%',
    })));
}
