import {NgComponentOutlet} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    OnDestroy,
    Optional,
    ViewChild,
} from '@angular/core';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import {Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {AbstractPlatform} from '../platforms/platform';
import {ControllerClientServiceBase} from '../shared/controller-client.service.base';
import {LayoutEntity} from '../sync/settings.service';
import {SyncServiceBase} from '../sync/sync.service.base';
import {EventPlayer, EventSchedulerService} from './event-scheduler.service';
import {BaseLayoutComponent} from './layout/base-layout.component';
import {layoutClasses} from './layout/layout-classes';
import {PlaylistSyncService} from './playlist-sync.service';
import {Playlist, PlaylistEntry, SchedulerService} from './scheduler.service';
import {Layout} from './scheduler.service.base';

const logger = Logger.get('playlist-player');
const errorMessagePrepare = 'ERROR: Failed to prepare any playlist entry.';
const errorMessagePlay = 'ERROR: Failed to play any playlist entry.';

/**
 * The playlist player takes the current playlist from the {@link SchedulerService}
 * and displays it using layout components defined in {@link layoutClasses}.
 *
 * High level features are:
 * - displaying playlist entries using layouts defined in {@link layoutClasses}.
 * - displaying events by implementing the {@link EventPlayer} interface.
 * - displaying playlist entries synchronized using the {@link PlaylistSyncService}.
 * - controlling panel state depending on playlist (empty playlist -> panel off).
 */
@Component({
    selector: 'siq-playlist-player',
    // tslint:disable:no-unused-css
    styles: [`
        :host {
            width: 100%;
            height: 100%;
            position: fixed;
        }

        .error-text {
            position: absolute;
            bottom: 10px;
            width: 100%;
            font-size: 20px;
            color: #F9BC15;
            background-color: black;
            text-align: center;
            z-index: 100;
        }

        Label {
            margin-top: 140px;
            font-size: 20px;
            color: #F9BC15;
            text-align: center;
        }
    `],
    templateUrl: 'playlist-player.html',
})
export class PlaylistPlayerComponent implements OnDestroy, AfterViewInit, EventPlayer {
    layoutComponentClass?: any;
    @ViewChild(NgComponentOutlet, {static: false}) componentOutlet?: NgComponentOutlet;

    // If error text is set it is shown in a small font at the bottom of the display
    errorText?: string;
    errorRetryTimeout?: any;

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private playlist: Playlist = [];
    /** Ignore scheduler playlist events while trying to update playlist on content end. */
    private tryingPlaylistUpdate = false;
    /** playlistIdx shows which playlist entry is currently being played. undefined means nothing is played. */
    private playlistIdx?: number = undefined;
    /** playlistPrepareIdx shows which playlist entry is currently prepared. undefined means nothing is prepared. */
    private playlistPrepareIdx?: number = undefined;
    /**
     * eventState signals that event is playing or going to play
     * An event can not be interrupted except from another event.
     */
    private eventState: 'NONE' | 'PREPARING' | 'PREPARED' | 'PLAYING' = 'NONE';
    private restartingForMissingSyncScreen = false;
    /** playFailCount counts the number of consecutive errors in layout.play() */
    private playFailCount = 0;

    private layout?: Layout;
    private layoutComponent?: BaseLayoutComponent;
    private layoutCompletedSubscription?: Subscription;
    private layoutComponentLock = new Lock();

    constructor(private cdr: ChangeDetectorRef,
                private platform: AbstractPlatform,
                private sync: SyncServiceBase,
                private scheduler: SchedulerService,
                private eventScheduler: EventSchedulerService,
                @Optional() private playlistSync: PlaylistSyncService,
                private controller: ControllerClientServiceBase) {
    }

    ngAfterViewInit(): void {
        this.scheduler.getPlaylist$()
            .pipe(
                map((playlist, index): [Playlist, number] => [playlist, index]),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe(async ([playlist, index]) => {
                logger.info('Playlist changed. New Length: ' + playlist.length, {playlist});

                // Do not trigger playing by scheduler while playing an event.
                if (this.eventState !== 'NONE') {
                    logger.info('Ignoring new playlist while playing event.');
                    return;
                }

                // Call onPlaylistChange() the first time we receive a playlist change
                // and every time we change from an empty playlist to a non-empty one
                if (!(index === 0 || (this.playlist.length === 0 && playlist.length > 0))) {
                    return;
                }

                if (this.tryingPlaylistUpdate) {
                    logger.info('Ignoring new playlist while trying to update playlist on content end.');
                    return;
                }

                this.playlist = playlist;
                await this.onPlaylistChange();
            });

        if (this.playlistSync) {
            this.playlistSync.receivedMissingScreen
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((screen: number) => this.onReceivedMissingSyncScreen(screen));
        }
        // Register ourselves as the current EventPlayer.
        this.eventScheduler.start(this);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        clearTimeout(this.errorRetryTimeout);

        if(this.playlistSync) {
            this.playlistSync.cancelWaitForScreenGroup('ngOnDestroy');
        }
        this.eventScheduler.stop();
    }

    async prepareEvent(entry: PlaylistEntry): Promise<boolean> {
        this.leaveErrorMode();

        try {
            // panel might not be enabled (we might have an empty playlist).
            if (!await this.platform.getPanelEnabled()) {
                await this.platform.setPanelEnabled(true);
            }
            if(this.playlistSync) {
                this.playlistSync.cancelWaitForScreenGroup('prepareEvent');
            }
            await this.layoutComponentLock.dispatch(async () => {
                this.playlistPrepareIdx = undefined;
                if (this.layoutComponent !== undefined) {
                    this.layoutComponent.stop();
                }

                if (entry.layout !== undefined) {
                    this.useLayoutFromEvent(entry.layout);
                } else if (this.layoutComponent === undefined) {
                    // We might not have loaded a layout currently,
                    // if no layout is loaded use the default layout template.
                    this.layout = {template: 'default'};
                    this.changeLayoutTemplate('default');
                }

                // Set a flag indicating that the currently playing entry
                // cannot be synchronized using the PlaylistSyncService
                this.eventState = 'PREPARING';

                if (!this.layoutComponent || !(await this.layoutComponent.prepare(entry))) {
                    throw new Error('Failed to prepare layout component.');
                }

                this.eventState = 'PREPARED';
            });
        } catch (e) {
            logger.warn(`Failed to prepare event: ${e.message}`);
            this.onContentEnd();
            return false;
        }

        return true;
    }

    async playEvent(entry: PlaylistEntry): Promise<void> {
        const continueWithoutEvent = await this.layoutComponentLock.dispatch(async () => {
            if (this.eventState !== 'PREPARED') {
                logger.warn(`Cannot play event in event-state: ${this.eventState}`);
                return false;
            }
            this.eventState = 'PLAYING';

            if (!this.layoutComponent || !await this.layoutComponent.play(entry)) {
                logger.warn('Failed to play event.');
                return true;
            }
            this.controller.sendContentChange(entry.id);

            // After the event has finished onContentEnd() will be called
            // and continue the playlist with the next prepared entry.
            // the entry that got canceled by prepareEvent() will be skipped.

            if (this.playlist.length > 0) {
                await this.prepareNextEntry();
            }
            return false;
        });
        if (continueWithoutEvent) {
            this.onContentEnd();
        }
    }

    private useLayoutFromEvent(layout: Layout): void {
        this.layout = layout;
        this.changeLayoutTemplate(layout.template);
    }

    private async onReceivedMissingSyncScreen(screenId: number): Promise<void> {
        if (this.eventState !== 'NONE' || this.layoutComponent === undefined) {
            return;
        }

        if (this.restartingForMissingSyncScreen) {
            // Possibly playing is being started at this time from previous missing screen event.
            // But we accept start playing a little early to prevent trouble with multiple start and abort playing.
            logger.info(`Ignoring sync from another missing screen: ${screenId}`);
            return;
        }

        logger.info(`Restarting playlist after receiving missing screen ${screenId} from playlist synchronization.`);
        this.restartingForMissingSyncScreen = true;
        await this.onPlaylistChange();
        this.restartingForMissingSyncScreen = false;
    }

    private getNextPlaylistIdx(): number {
        return this.playlistIdx === undefined ? 0 : (this.playlistIdx + 1) % this.playlist.length;
    }

    private getLayoutIdFromPlaylistEntry(id: number): number | undefined {
        if (!this.playlist[id] || !this.playlist[id].layout || !(this.playlist[id].layout as LayoutEntity).id) {
            return;
        }
        return (this.playlist[id].layout as LayoutEntity).id;
    }

    /**
     * Called every time a item from the playlist has finished.
     */
    private onContentEnd(): void {
        // The only case where we aren't playing a syncable entry is events
        // so we reset syncable to true after every content (currently events only one content).
        this.eventState = 'NONE';

        // Trigger a playlist update and restart playlist if it changed
        if (this.tryUpdatePlaylist()) {
            this.onPlaylistChange();
            return;
        }

        // The playlist may be empty when for example we played an event when the playlist was empty
        if (this.playlist.length === 0) {
            this.onPlaylistChange();
            return;
        }

        // Continue on to the next entry that was prepared
        // but check if the layout changed and apply that changed if needed
        const currentLayoutId = (this.layout as LayoutEntity).id;
        const nextIdx = this.playlistPrepareIdx !== undefined ? this.playlistPrepareIdx : this.getNextPlaylistIdx();
        const nextLayoutId = this.getLayoutIdFromPlaylistEntry(nextIdx);

        if (currentLayoutId !== nextLayoutId) {
            this.onLayoutChange(nextIdx);
        }

        this.playAndPrepareNextLocked();
    }

    private tryUpdatePlaylist(): boolean {
        try {
            this.tryingPlaylistUpdate = true;

            // Do not apply a new playlist during sync as content may not have been downloaded yet.
            if (this.sync.isSyncing) {
                return false;
            }

            this.scheduler.triggerPlaylistUpdate();
            if (!_.isEqual(this.playlist, this.scheduler.getPlaylist())) {
                const newPlaylist = this.scheduler.getPlaylist();
                this.playlist = newPlaylist || [];
                return true;
            }

            return false;

        } finally {
            this.tryingPlaylistUpdate = false;
        }
    }

    private async prepareNextEntry(): Promise<boolean> {
        const startIdx = this.getNextPlaylistIdx();

        // Try to prepare the next entry until one entry is successfully prepared or until we tried them all
        for (let i = 0; i < this.playlist.length; i++) {
            const currentPrepareIdx = (startIdx + i) % this.playlist.length;
            if (this.layoutComponent && await this.layoutComponent.prepare(this.playlist[currentPrepareIdx])) {
                logger.debug('Successfully prepared playlist entry: ' + currentPrepareIdx);
                this.playlistPrepareIdx = currentPrepareIdx;
                return true;
            }
        }

        this.playlistPrepareIdx = undefined;
        return false;
    }

    private async playAndPrepareNextLocked(): Promise<void> {
        return this.layoutComponentLock.dispatch(this.playAndPrepareNext.bind(this));
    }

    private async playAndPrepareNext(): Promise<void> {
        if (!(await this.prepareNextEntry())) {
            logger.error('Could not prepare any playlist entry. Showing error text on display.');
            this.enterErrorMode(errorMessagePrepare);
            return;
        }
        // Set playlistIdx to the currently prepared entry to show which entry we are currently trying to play
        // playlistPrepareIdx can not be undefined as this point.
        this.playlistIdx = this.playlistPrepareIdx as number;
        const entry = this.playlist[this.playlistIdx];
        logger.debug('Playing playlist entry: ' + this.playlistIdx);

        // If we are starting the play the first entry of a playlist
        // we try to synchronize play using the PlaylistSyncService.
        if (this.playlistIdx === 0 && this.playlistSync) {
            try {
                await this.playlistSync.waitForScreenGroup();
            } catch (e) {
                logger.info(`Error on waiting for screen group: ${e}`);
                return;
            }
        }

        if (!this.layoutComponent || !await this.layoutComponent.play(entry)) {
            this.playFailCount += 1;
            if (this.playFailCount >= this.playlist.length) {
                // It seems we failed to play any entry in our playlist.
                // We show a error text on the display and try again when the playlist changes
                logger.error('Could not play any playlist entry. Showing error text on display.');
                this.enterErrorMode(errorMessagePlay);
                return;
            }

            logger.warn('Failed to play playlist entry. Continuing with next one.');
            // reset to undefined to force prepare the next one
            this.playlistPrepareIdx = undefined;

            // Check if layout of next entry is different and apply if needed.
            const nextIdx = this.getNextPlaylistIdx();
            const currentLayoutId = (this.layout as LayoutEntity).id;
            const nextLayoutId = this.getLayoutIdFromPlaylistEntry(nextIdx);
            if (currentLayoutId !== nextLayoutId) {
                this.onLayoutChange(nextIdx);
            }

            // play() failed to display the currently prepared element, try to play the next one instead ...
            return this.playAndPrepareNext();
        }

        this.leaveErrorMode();
        this.playFailCount = 0;
        this.controller.sendContentChange(entry.id);

        if (!(await this.prepareNextEntry())) {
            logger.warn('Could not pre-prepare any playlist entry.');
        }
    }

    private onPlaylistChange(): Promise<void> {
        logger.debug('playlist-player:onPlaylistChange()');

        return this.layoutComponentLock.dispatch(async () => {
            logger.debug('playlist-player:onPlaylistChange got lock');

            this.leaveErrorMode();
            this.playFailCount = 0;
            this.playlistIdx = undefined;
            this.onLayoutChange(0);

            // Enable / disable panel depending on whether we have a playlist
            this.platform.setPanelEnabled(this.playlist.length > 0).then();

            // Only start playing if the playlist isn't empty ...
            if (this.playlist.length > 0) {
                await this.playAndPrepareNext();
            }
        });
    }

    private onLayoutChange(playlistIdx: number): void {
        logger.debug('playlist-player:onLayoutChange()');

        // Always reset prepareIdx to signal that the next content is not yet prepared
        this.playlistPrepareIdx = undefined;

        if (this.layoutComponent !== undefined) {
            try {
                this.layoutComponent.stop();
            } catch (e) {
                logger.warn(`Failed to stop layout component for layout change: ${e.message}`);
            }
        }

        // If only the layout changes but not the template
        const isSameLayoutTemplate = this.layout
            && this.playlist[playlistIdx] && this.playlist[playlistIdx].layout
            && this.layout.template === (this.playlist[playlistIdx].layout as Layout).template;
        if (isSameLayoutTemplate) {
            logger.debug('Using quick layout change because template is the same');

            this.layout = this.playlist[playlistIdx].layout;
            (this.layoutComponent as any).layout = this.layout;

            return;
        }

        if (this.layoutCompletedSubscription !== undefined) {
            this.layoutCompletedSubscription.unsubscribe();
            this.layoutCompletedSubscription = undefined;
        }

        if (this.playlist.length === 0) {
            this.layout = undefined;
            this.layoutComponent = undefined;
            this.layoutComponentClass = undefined;
            return;
        }

        this.layout = this.playlist[playlistIdx].layout;

        // Set the correct layout class
        if (this.layout) {
            this.changeLayoutTemplate(this.layout.template);
        }
    }

    private changeLayoutTemplate(layoutTemplate: string): void {
        if (this.layoutCompletedSubscription !== undefined) {
            this.layoutCompletedSubscription.unsubscribe();
            this.layoutCompletedSubscription = undefined;
        }

        this.layoutComponentClass = layoutClasses[layoutTemplate];

        if (this.layoutComponentClass === undefined) {
            logger.error(`LayoutComponentClass for template '${layoutTemplate}' not found. Using default layout instead.`);
            this.layoutComponentClass = layoutClasses.default;
        }

        // Update the view after the layout has changed
        this.cdr.detectChanges();

        // And set the layoutComponent
        const componentRef = (this.componentOutlet as any)._componentRef as ComponentRef<BaseLayoutComponent>;
        this.layoutComponent = componentRef.instance;
        (this.layoutComponent as any).layout = this.layout;  // @ObservableInput() generates wrong types.
        this.layoutCompletedSubscription = this.layoutComponent.completed.subscribe(this.onContentEnd.bind(this));

        // Update the view after we set the layout on the layout component
        this.cdr.detectChanges();
    }

    private enterErrorMode(errorText: string): void {
        this.errorText = errorText;

        if (this.errorRetryTimeout !== undefined) {
            logger.warn('Error mode retry timer is already active, clearing it.');
            clearTimeout(this.errorRetryTimeout);
            this.errorRetryTimeout = undefined;
        }

        this.errorRetryTimeout = setTimeout(() => {
            logger.debug('Retrying to play from error mode.');
            this.playFailCount = 0;
            this.errorRetryTimeout = undefined;

            // Set the player state so that we can call onContentEnd even though we haven't played anything.
            this.playlistPrepareIdx = undefined;
            if (this.playlistIdx === undefined) {
                this.playlistIdx = 0;
            }

            // Start from a clean state.
            if (this.layoutComponent !== undefined) {
                try {
                    this.layoutComponent.stop();
                } catch (e) {
                    logger.warn(`Failed to stop layout component for retry: ${e.message}`);
                }
            }

            this.onContentEnd();
        }, 15_000);

        // Enable the panel to ensure that the error text can be read
        this.platform.setPanelEnabled(true);

    }

    private leaveErrorMode(): void {
        if (this.errorText === undefined && this.errorRetryTimeout === undefined) {
            return;
        }

        clearTimeout(this.errorRetryTimeout);
        this.errorRetryTimeout = undefined;
        this.errorText = undefined;
        logger.info('Left error mode.');
    }
}

// Adapted from https://spin.atomicobject.com/2018/09/10/javascript-concurrency/
class Lock {
    private mutex = Promise.resolve();

    lock(): Promise<() => void> {
        let begin: (unlock: () => void) => void = () => undefined;

        this.mutex = this.mutex.then(() => new Promise(begin));

        return new Promise(res => {
            begin = res;
        });
    }

    async dispatch<T>(fn: (() => Promise<T>) | (() => T)): Promise<T> {
        const unlock = await this.lock();
        try {
            return await fn();
        } finally {
            unlock();
        }
    }
}
