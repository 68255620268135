import * as tslib_1 from "tslib";
import { SMPCloseEvent, SMPConnection, SMPOpenEvent, WebsocketTransport } from '@shopiq/smp';
import * as Logger from 'js-logger';
import * as _ from 'lodash';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { filter, first, map, shareReplay, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SettingKeys } from '../sync/settings.service';
import { SyncEndEvent, SyncErrorEvent, SyncStartEvent } from '../sync/sync.service.base';
import { addLogHandler, removeLogHandler } from './logging';
import { TimeService } from './time.service';
var ControllerConnectingEvent = /** @class */ (function () {
    function ControllerConnectingEvent() {
    }
    return ControllerConnectingEvent;
}());
export { ControllerConnectingEvent };
var ControllerClientServiceBase = /** @class */ (function () {
    function ControllerClientServiceBase(platform, settings, sync, timeService, patchZone, transportClass) {
        var _this = this;
        if (patchZone === void 0) { patchZone = false; }
        if (transportClass === void 0) { transportClass = WebsocketTransport; }
        this.platform = platform;
        this.settings = settings;
        this.sync = sync;
        this.timeService = timeService;
        this.patchZone = patchZone;
        this.transportClass = transportClass;
        this.logger = Logger.get('controller');
        this.eventsSubject = new Subject();
        this.screenCaptureState$ = of(false);
        this.sync.events.subscribe(function (event) {
            if (_this.connection === undefined || !_this.connection.connected) {
                return;
            }
            if (event instanceof SyncErrorEvent) {
                _this.connection.publish(_this.url + '.sync_error', event.type.toString() + ": " + event.message)
                    .catch(function (error) { return _this.logger.debug("Failed to publish sync error: " + error.message); });
            }
            if (event instanceof SyncStartEvent) {
                _this.connection.publishIfSubscribed(_this.url + '.sync', true);
            }
            if (event instanceof SyncEndEvent) {
                _this.connection.publishIfSubscribed(_this.url + '.sync', false);
            }
        });
        this.platform.panelEnabledChange.subscribe(function (panelOnStatus) {
            if (_this.connection !== undefined) {
                _this.connection.publishIfSubscribed(_this.url + '.panel', panelOnStatus);
            }
        });
    }
    Object.defineProperty(ControllerClientServiceBase.prototype, "events", {
        get: function () {
            return this.eventsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControllerClientServiceBase.prototype, "isConnectionStarted", {
        get: function () {
            return this.connection !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControllerClientServiceBase.prototype, "connected", {
        get: function () {
            return this.connection !== undefined && this.connection.connected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControllerClientServiceBase.prototype, "url", {
        get: function () {
            return 'ssc.screen.' + this.settings.get(SettingKeys.SCREEN_ID);
        },
        enumerable: true,
        configurable: true
    });
    ControllerClientServiceBase.prototype.connect = function () {
        var _this = this;
        this.eventsSubject.next(new ControllerConnectingEvent());
        // Create connection
        this.connection = new SMPConnection(new this.transportClass(environment.controllerAddress, false, this.patchZone), function () { return Promise.resolve(_this.settings.get(SettingKeys.CONTROLLER_TOKEN)); });
        this.connection.connect();
        // Setup log handler when connected & forward smp events
        this.connection.events.subscribe(function (e) {
            if (e instanceof SMPOpenEvent) {
                // ensure the previous logger handler is removed
                removeLogHandler('controller');
                addLogHandler('controller', _this.remoteLogHandler.bind(_this));
            }
            if (e instanceof SMPCloseEvent) {
                removeLogHandler('controller');
            }
            _this.eventsSubject.next(e);
        });
        // Register our endpoints
        this.connection.register(this.url + '.info', this.getScreenInfo.bind(this));
        this.connection.register(this.url + '.reboot', function () {
            _this.platform.reboot();
        });
        this.connection.register(this.url + '.sync', function () {
            _this.sync.start();
        });
        this.connection.register(this.url + '.debug_eval', this.debugEval.bind(this));
        // Send screen captures if someone subscribes to them
        this.screenCaptureState$ = this.connection
            .subscribe$("meta.topic_changed." + this.url + ".screen_capture")
            .pipe(map(function (change) { return change === 'CREATED'; }), 
        // Ensure one value is always present when subscribing,
        // will be overridden by shareReplay() as soon as the first value arrives
        startWith(false), shareReplay(1));
        this.screenCaptureState$
            .pipe(filter(function (state) { return state; }))
            .subscribe(function () { return _this.sendScreenCapture(); });
    };
    ControllerClientServiceBase.prototype.sendContentChange = function (content) {
        if (this.connection !== undefined) {
            this.connection.publishIfSubscribed(this.url + '.content', content);
        }
    };
    ControllerClientServiceBase.prototype.getScreenInfoPairs = function () {
        var _this = this;
        return [
            ['app_update_url', function () { return _this.platform.appUpdateUrl; }],
            ['app_version', _.constant(environment.release)],
            ['app_server', _.constant(environment.serverAddress)],
            ['serial', this.platform.getSerialNumber.bind(this.platform)],
            ['firmware_version', this.platform.getFirmwareVersion.bind(this.platform)],
            ['model', function () { return _this.platform.model; }],
            ['mac_address', this.platform.getMacAddress.bind(this.platform)],
            ['ip_address', this.platform.getIpAddress.bind(this.platform)],
            ['connected_wifi_ssid', this.platform.getConnectedWifiSsid.bind(this.platform)],
            ['connected_wifi_signal_strength', this.platform.getConnectedWifiSignalStrength.bind(this.platform)],
            ['current_time', function () { return TimeService.now().unix(); }],
            ['panel_status', this.platform.getPanelEnabled.bind(this.platform)],
            ['system_up_time', function () { return _this.platform.getSystemUptime() / 1000; }],
            ['cpu_load', this.platform.getCpuLoad.bind(this.platform)],
            [
                'internal_storage_available_capacity',
                this.platform.getInternalStorageAvailableCapacity.bind(this.platform),
            ],
        ];
    };
    ControllerClientServiceBase.prototype.getScreenInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var infoPromises, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        infoPromises = this.getScreenInfoPairs().map(function (_a) {
                            var key = _a[0], apiFunc = _a[1];
                            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _b, e_1;
                                return tslib_1.__generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _c.trys.push([0, 2, , 3]);
                                            _b = [key];
                                            return [4 /*yield*/, Promise.resolve(apiFunc())];
                                        case 1: return [2 /*return*/, _b.concat([_c.sent()])];
                                        case 2:
                                            e_1 = _c.sent();
                                            this.logger.debug("Failed to get " + key + " for screen info: " + e_1.message);
                                            return [2 /*return*/, undefined];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        _a = _;
                        return [4 /*yield*/, Promise.all(infoPromises)];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])
                            .filter(function (item) { return item !== undefined; })
                            .fromPairs()
                            .value()];
                }
            });
        });
    };
    ControllerClientServiceBase.prototype.createDebugEvalContext = function () {
        return {
            TimeService: TimeService,
            timeService: this.timeService,
            moment: moment,
            environment: environment,
            Logger: Logger,
            platform: this.platform,
            syncService: this.sync,
            settings: this.settings,
        };
    };
    ControllerClientServiceBase.prototype.debugEval = function (cmd) {
        try {
            // Make some variables accessible
            var context = this.createDebugEvalContext();
            return eval(cmd);
        }
        catch (e) {
            this.logger.error('Error while executing command: ' + e.message);
        }
    };
    ControllerClientServiceBase.prototype.remoteLogHandler = function (level, name, messages) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connection) {
                            console.warn('Could not send log message to server. Dropping message.', messages);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.connection.call('ssc.server.log', TimeService.nowMillis(false), name, level.name, messages)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.warn('Could not send log message to server. Dropping message.', e_2, messages);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ControllerClientServiceBase.prototype.sendScreenCapture = function (interval) {
        if (interval === void 0) { interval = 5000; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imageBuffer, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.connection) {
                            this.logger.debug('Not connected. Waiting before sending screen capture.');
                            this.scheduleSendScreenCapture(interval);
                            return [2 /*return*/];
                        }
                        // Don't send another screen capture as long as we're still sending data
                        if (this.connection.bufferedAmount > 100) {
                            this.logger.debug('Connection still has a send buffer > 100 bytes. Waiting before sending another screen capture.');
                            this.scheduleSendScreenCapture(interval);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        this.logger.trace('Capturing screen and publishing.');
                        return [4 /*yield*/, this.platform.captureScreen()];
                    case 2:
                        imageBuffer = _a.sent();
                        return [4 /*yield*/, this.connection.publish(this.url + '.screen_capture', imageBuffer)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.logger.warn("Could not capture screen: " + error_1.message);
                        return [3 /*break*/, 5];
                    case 5:
                        this.scheduleSendScreenCapture(interval);
                        return [2 /*return*/];
                }
            });
        });
    };
    ControllerClientServiceBase.prototype.scheduleSendScreenCapture = function (interval) {
        var _this = this;
        if (this.screenCaptureTimer !== undefined) {
            this.logger.warn('scheduleSendScreenCapture() called while screen capture already scheduled');
            return;
        }
        this.screenCaptureTimer = setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var screenCaptureState;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.screenCaptureTimer = undefined;
                        return [4 /*yield*/, this.screenCaptureState$.pipe(first()).toPromise()];
                    case 1:
                        screenCaptureState = _a.sent();
                        if (!this.connected || !screenCaptureState) {
                            this.logger.debug('Stop sending screen captures.');
                            return [2 /*return*/];
                        }
                        this.sendScreenCapture(interval);
                        return [2 /*return*/];
                }
            });
        }); }, interval);
    };
    return ControllerClientServiceBase;
}());
export { ControllerClientServiceBase };
