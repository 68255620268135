import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { FileSyncServiceBase } from '../../sync/file-sync.service.base';
import { SettingsService } from '../../sync/settings.service';
import { BaseLayoutComponent } from './base-layout.component';
import { createIframeSrcDoc } from './iframe-utils';
var HtmlOverlayLayoutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HtmlOverlayLayoutComponent, _super);
    function HtmlOverlayLayoutComponent(settings, fileSync, sanitizer) {
        var _this = _super.call(this, settings, fileSync) || this;
        _this.sanitizer = sanitizer;
        _this.htmlCode$ = createHtmlCode$(_this.fileSync, _this.settings, _this.sanitizer, _this.parameters$);
        _this.contentStyle$ = createContentStyle$(_this.parameters$);
        return _this;
    }
    return HtmlOverlayLayoutComponent;
}(BaseLayoutComponent));
export { HtmlOverlayLayoutComponent };
function createHtmlCode$(fileSync, settings, sanitizer, parameters$) {
    return parameters$.pipe(map(function (parameters) {
        var assets = settings.getHtmlTemplateAssetsForTemplate(parameters.template);
        var source = createIframeSrcDoc(parameters.overlay_html, assets, fileSync);
        return sanitizer.bypassSecurityTrustHtml(source);
    }));
}
function createContentStyle$(parameters$) {
    var addPx = function (pixel) { return pixel ? pixel + "px" : undefined; };
    return parameters$.pipe(map(function (parameters) { return ({
        zIndex: '0',
        position: 'absolute',
        left: addPx(_.get(parameters, 'content_dimensions.posX', 0)),
        top: addPx(_.get(parameters, 'content_dimensions.posY', 0)),
        width: addPx(_.get(parameters, 'content_dimensions.width')) || '100%',
        height: addPx(_.get(parameters, 'content_dimensions.height')) || '100%',
    }); }));
}
